import type { UiButtonVariant } from '~/types/ui'

export function useStoryblokUi() {
  const buttonVariant = (blokButtonVariant: number | string): UiButtonVariant =>
    (typeof blokButtonVariant === 'string' ? blokButtonVariant : 'sky') as UiButtonVariant

  const headingTag = (blokHeadingTag: number | string, defaultTag = 'h2'): string =>
    typeof blokHeadingTag === 'string' ? blokHeadingTag : defaultTag

  return { buttonVariant, headingTag }
}
